"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.client = void 0;
Object.defineProperty(exports, "gql", {
  enumerable: true,
  get: function () {
    return _graphqlTag.default;
  }
});
exports.webSocket = exports.subscribe = exports.query = exports.mutate = void 0;
var _apolloLinkWs = require("apollo-link-ws");
var _apolloUtilities = require("apollo-utilities");
var _apolloLinkHttp = require("apollo-link-http");
var _apolloLink = require("apollo-link");
var _apolloBoost = require("apollo-boost");
var _apolloCacheInmemory = require("apollo-cache-inmemory");
var _graphqlTag = _interopRequireDefault(require("graphql-tag"));
var _apolloLinkContext = require("apollo-link-context");
var _jwtDecode2 = _interopRequireDefault(require("jwt-decode"));
const HASURA_GRAPHQL_ENGINE_HOSTNAME = "graphql-remc.ecoledeconduitecastraise.com";
const httpLink = new _apolloLinkHttp.HttpLink({
  uri: `https://${HASURA_GRAPHQL_ENGINE_HOSTNAME}/v1/graphql`
});
const webSocket = new _apolloLinkWs.WebSocketLink({
  uri: `wss://${HASURA_GRAPHQL_ENGINE_HOSTNAME}/v1/graphql`,
  options: {
    lazy: true,
    reconnect: true,
    reconnectionAttempts: 5,
    connectionCallback: error => {
      if (error) {
        console.log(error);
      }
    },
    connectionParams: {
      get headers() {
        return {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        };
      }
    }
  }
});
exports.webSocket = webSocket;
const authLink = (0, _apolloLinkContext.setContext)((_, {
  headers
}) => {
  let token = localStorage.getItem("token");
  let validToken = true;
  try {
    var _jwtDecode;
    validToken = ((_jwtDecode = (0, _jwtDecode2.default)(token)) === null || _jwtDecode === void 0 ? void 0 : _jwtDecode.exp) - +new Date() / 1000 > 0;
  } catch (error) {
    validToken = false;
    console.error("error: ", error);
  }
  return {
    headers: {
      ...headers,
      ...(token && validToken && {
        Authorization: `Bearer ${token}`
      })
    }
  };
});
const client = (() => {
  const link = (0, _apolloLink.split)(({
    query
  }) => {
    const _getMainDefinition = (0, _apolloUtilities.getMainDefinition)(query),
      kind = _getMainDefinition.kind,
      operation = _getMainDefinition.operation;
    return kind === "OperationDefinition" && operation === "subscription";
  }, webSocket, authLink.concat(httpLink));
  return new _apolloBoost.ApolloClient({
    link,
    cache: new _apolloCacheInmemory.InMemoryCache(),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "cache-and-network",
        errorPolicy: "ignore"
      },
      query: {
        fetchPolicy: "no-cache",
        errorPolicy: "all"
      },
      mutate: {
        errorPolicy: "all"
      }
    }
  });
})();
exports.client = client;
const subscribe = ({
  query,
  variables = {},
  next = () => null,
  error = err => null
}) => {
  return client.subscribe({
    query,
    variables
  }).subscribe({
    next,
    error(err) {
      console.error("err: ", err);
      error === null || error === void 0 ? void 0 : error(err);
    }
  });
};
exports.subscribe = subscribe;
const query = ({
  query,
  variables = {}
}) => {
  return client.query({
    query,
    variables
  });
};
exports.query = query;
const mutate = ({
  mutation,
  variables = {}
}) => {
  return client.mutate({
    mutation,
    variables
  });
};
exports.mutate = mutate;