"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.resetInputs = exports.inputNames = exports.getInputContainers = exports.getInput = exports.getContainers = exports.formIsValid = void 0;
var _n3Utils = require("components/n3-utils");
const inputNames = ["n3-input", "n3-city-input", "n3-country-input", "n3-department-input", "n3-datepicker", "n3-dropdown", "n3-gender-input", "n3-number-input", "n3-textfield", "n3-plaque-immatriculation", "n3-password-input", "n3-segments", "n3-timepicker", "n3-input-container", "n3-date-picker", "n3-date-range"];
exports.inputNames = inputNames;
const formIsValid = async (element, form, scroll = true, height = 0) => {
  var _element$shadowRoot;
  await element.updateComplete;
  form = typeof form == "string" ? (_element$shadowRoot = element.shadowRoot) === null || _element$shadowRoot === void 0 ? void 0 : _element$shadowRoot.querySelector(form) : form;
  const inputs = form ? form.querySelectorAll(inputNames.join(",")) : [];
  let requiredInputs = [];
  let notRequiredDates = [];
  inputs.forEach(input => {
    if (input.tagName.toLowerCase() === "n3-input") input = input.shadowRoot.firstElementChild;
    input.validate || typeof input.isvalid === "function" ? requiredInputs = [...requiredInputs, input] : notRequiredDates = [...notRequiredDates, input.tagName.toLowerCase() === "n3-datepicker" ? input : null].filter(i => i);
  });
  // requiredInputs.map(input => (input.validate = true));
  const containers = getInputContainers(requiredInputs);
  containers.map(container => container.validateInput());
  const firstInvalidInput = containers.find(input => input.notValid);
  if (firstInvalidInput && scroll) {
    var _host$getRootNode$hos;
    const scroller = (0, _n3Utils.getScrollParent)(firstInvalidInput);
    let host = firstInvalidInput.getRootNode().host;
    host = ((_host$getRootNode$hos = host.getRootNode().host) === null || _host$getRootNode$hos === void 0 ? void 0 : _host$getRootNode$hos.tagName) === "N3-DROPDOWN" ? host.getRootNode().host : host;
    if (scroller) scroller.scrollTo({
      top: host.offsetTop - 10 - height,
      behavior: "smooth"
    });
  }
  // console.log(
  //   containers.filter(container => container.notValid),
  //   notRequiredDates.filter(date => date.shadowRoot.querySelector("n3-input-container").notValid)
  // );
  return containers.every(container => !container.notValid) && notRequiredDates.every(date => {
    var _date$shadowRoot;
    return !((_date$shadowRoot = date.shadowRoot) !== null && _date$shadowRoot !== void 0 && _date$shadowRoot.querySelector("n3-input-container").notValid);
  });
};
exports.formIsValid = formIsValid;
const resetInputs = async element => {
  var _element$querySelecto, _getInputContainers;
  await element.updateComplete;
  const form = (_element$querySelecto = element.querySelector(".form")) !== null && _element$querySelecto !== void 0 ? _element$querySelecto : element;
  const inputs = form ? [...form.querySelectorAll(inputNames.join(","))] : [];
  ((_getInputContainers = getInputContainers(inputs)) !== null && _getInputContainers !== void 0 ? _getInputContainers : []).map(input => input.firstValidation = true);
};
exports.resetInputs = resetInputs;
const getInput = element => {
  let host = element.getRootNode().host;
  if (inputNames.some(input => {
    var _host, _host$getRootNode, _host$getRootNode$hos2, _host$getRootNode$hos3;
    return input === ((_host = host) === null || _host === void 0 ? void 0 : (_host$getRootNode = _host.getRootNode()) === null || _host$getRootNode === void 0 ? void 0 : (_host$getRootNode$hos2 = _host$getRootNode.host) === null || _host$getRootNode$hos2 === void 0 ? void 0 : (_host$getRootNode$hos3 = _host$getRootNode$hos2.tagName) === null || _host$getRootNode$hos3 === void 0 ? void 0 : _host$getRootNode$hos3.toLowerCase());
  })) host = getInput(host);
  return host;
};
exports.getInput = getInput;
const getInputContainers = inputs => {
  let containers = [];
  inputs.map(async input => {
    getContainers(input).flat(Infinity).map(i => i ? containers = [...containers, i] : null);
  });
  return containers;
};
exports.getInputContainers = getInputContainers;
const getContainers = input => {
  var _input$tagName;
  if (!input.shadowRoot) return;
  let arr = [];
  if (((_input$tagName = input.tagName) === null || _input$tagName === void 0 ? void 0 : _input$tagName.toLowerCase()) === "n3-input-container") arr.push(input);
  input.shadowRoot.querySelectorAll("n3-input-container").forEach(el => arr = [...arr, el]);
  input.shadowRoot.querySelectorAll("*").forEach(i => {
    if (input.shadowRoot) arr = [...arr, getContainers(i)];
  });
  return arr;
};
exports.getContainers = getContainers;