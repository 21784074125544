"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.closest = void 0;
const closest = (el, component) => {
  const _closest = component.closest(el);
  if (_closest) return _closest;
  const host = component.getRootNode().host;
  if (host) return closest(el, component.getRootNode().host);else return null;
};
exports.closest = closest;