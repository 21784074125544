"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "Storage", {
  enumerable: true,
  get: function () {
    return _storage.Storage;
  }
});
exports.waitFor = exports.virtualizerScrollTo = exports.virtualizer = exports.unpack = exports.touch = exports.scrollY = exports.scroll = exports.rem2px = exports.pack = exports.numToWords = exports.nextpage = exports.isJSON = exports.is = exports.getScrollParent = exports.getInputContainer = exports.getCssProp = exports.getClosest = exports.filter = exports.entriesReduce = exports.dialog = exports.chunk = exports.capitalizeFirstLetter = void 0;
var _lzString = require("lz-string");
var _numToString = _interopRequireDefault(require("./num-to-string"));
var _index = require("lit/index.js");
require("components/n3-dialog");
require("components/n3-nextpage");
var _storage = require("./storage");
// import "lit-virtualizer";

const numToWords = num => {
  if (num) num = String(num);
  num = num.includes(".") ? num.split(".") : num.includes(",") ? num.split(",") : num;
  return Array.isArray(num) ? `${(0, _numToString.default)(num[0])} virgule ${(0, _numToString.default)(num[1])}` : (0, _numToString.default)(num);
};
exports.numToWords = numToWords;
const regex = /(auto|scroll)/;
const style = (node, prop) => getComputedStyle(node, null).getPropertyValue(prop);
const scroll = node => {
  return node instanceof HTMLElement ? regex.test(style(node, "overflow") + style(node, "overflow-y") + style(node, "overflow-x")) : false;
};
exports.scroll = scroll;
const scrollY = node => {
  return node instanceof HTMLElement ? /(hidden)/.test(style(node, "overflow-x")) && /(auto|scroll)/.test(style(node, "overflow-y")) : false;
};
exports.scrollY = scrollY;
const getScrollParent = (node, yOnly = false) => {
  if (!node || node === document.body) return document.body;
  if (yOnly ? scrollY(node) : scroll(node)) return node;
  if (node.hasAttribute("slot")) {
    var _node$parentNode$shad, _node$parentNode$shad2, _element$parentNode, _element$parentNode2, _element$parentNode2$;
    const slotName = `slot[name="${node.getAttribute("slot")}"]`;
    const element = (_node$parentNode$shad = (_node$parentNode$shad2 = node.parentNode.shadowRoot) === null || _node$parentNode$shad2 === void 0 ? void 0 : _node$parentNode$shad2.querySelector(slotName)) !== null && _node$parentNode$shad !== void 0 ? _node$parentNode$shad : node.parentNode;
    if (((_element$parentNode = element.parentNode) === null || _element$parentNode === void 0 ? void 0 : _element$parentNode.tagName) === "N3-MODAL" && (_element$parentNode2 = element.parentNode) !== null && _element$parentNode2 !== void 0 && (_element$parentNode2$ = _element$parentNode2.shadowRoot) !== null && _element$parentNode2$ !== void 0 && _element$parentNode2$.querySelector("slot")) return getScrollParent(element.parentNode.shadowRoot.querySelector("slot"), yOnly);
    return getScrollParent(element, yOnly);
  } else return getScrollParent(node.parentNode ? node.parentNode.host || node.parentNode : null, yOnly);
};
exports.getScrollParent = getScrollParent;
const getInputContainers = inputs => {
  let containers = [];
  inputs.map(async input => {
    getContainers(input).flat(Infinity).map(i => i ? containers = [...containers, i] : null);
  });
  return containers;
};
exports.getInputContainer = getInputContainers;
const getContainers = input => {
  var _input$shadowRoot, _input$shadowRoot2;
  if (!input.shadowRoot) return;
  let arr = [];
  (_input$shadowRoot = input.shadowRoot) === null || _input$shadowRoot === void 0 ? void 0 : _input$shadowRoot.querySelectorAll("n3-input-container").forEach(el => arr = [...arr, el]);
  (_input$shadowRoot2 = input.shadowRoot) === null || _input$shadowRoot2 === void 0 ? void 0 : _input$shadowRoot2.querySelectorAll("*").forEach(i => {
    if (input.shadowRoot) arr = [...arr, getContainers(i)];
  });
  return arr;
};
const isJSON = string => {
  try {
    JSON.parse(string);
  } catch (e) {
    return false;
  }
  return true;
};
exports.isJSON = isJSON;
const pack = object => (0, _lzString.compress)(isJSON(object) ? JSON.stringify(object) : object);
exports.pack = pack;
const unpack = string => isJSON(string) ? JSON.parse((0, _lzString.decompress)(string)) : (0, _lzString.decompress)(string);
exports.unpack = unpack;
const is = (condition, template) => condition ? template : null;

// class _Storage {
//   set(key, value, comp = false) {
//     return new Promise(async resolve => {
//       this.toJson(value, comp, key);
//       document.dispatchEvent(
//         new CustomEvent("storage-updated", {
//           bubbles: true,
//           composed: false,
//           detail: { key, value }
//         })
//       );
//       resolve();
//     });
//   }

//   get(key) {
//     const json = localStorage.getItem(key);
//     return this.fromJson(json);
//   }

//   rm(key) {
//     localStorage.removeItem(key);
//   }

//   toJson(value, comp, key) {
//     return new Promise(resolve => {
//       const worker = new Worker("./worker-compress", { type: "module" });
//       worker.addEventListener("message", ({ data }) => {
//         setTimeout(() => {
//           resolve({ data });
//           localStorage.setItem(key, JSON.stringify({ data }));
//         });
//       });
//       worker.postMessage({ value, comp });
//     });
//   }

//   fromJson(value) {
//     if (isJSON(value)) {
//       const json = JSON.parse(value)?.data ?? JSON.parse(value);
//       const dec = typeof json == "string" ? decompress(json) ?? json : json;
//       return isJSON(dec) ? JSON.parse(dec)?.data ?? JSON.parse(dec) : dec;
//     } else return typeof value == "string" ? decompress(value) || value : value;
//   }
// }
// export const Storage = new _Storage();
exports.is = is;
const dialog = (params = {}) => {
  var _window$Nostria$conta, _window$Nostria;
  const _ref = params !== null && params !== void 0 ? params : {},
    head = _ref.head,
    message = _ref.message,
    cancel = _ref.cancel,
    reject = _ref.reject,
    confirm = _ref.confirm,
    cancelTheme = _ref.cancelTheme,
    rejectTheme = _ref.rejectTheme,
    confirmTheme = _ref.confirmTheme,
    canReject = _ref.canReject,
    icon = _ref.icon,
    iconTheme = _ref.iconTheme;
  const dialog = document.createElement("n3-dialog");
  const container = document.createElement("div");
  (0, _index.render)(message ? (0, _index.html)` ${message} ` : (0, _index.html)` <p>Êtes-vous certain de vouloir confirmer cette action ?</p> `, container);
  Object.assign(dialog, {
    head,
    ...(icon && {
      icon
    }),
    ...(iconTheme && {
      iconTheme
    }),
    ...(cancel && {
      cancel
    }),
    ...(reject && {
      reject
    }),
    ...(confirm && {
      confirm
    }),
    ...(cancelTheme && {
      cancelTheme
    }),
    ...(rejectTheme && {
      rejectTheme
    }),
    ...(confirmTheme && {
      confirmTheme
    }),
    ...(canReject && {
      canReject
    })
  });
  const events = ["close", "confirm", "reject"];
  events.map(x => {
    const event = `on${capitalizeFirstLetter(x)}`;
    !params[event] || dialog.addEventListener(x, e => params[event](e.detail.dialog));
  });
  (0, _index.render)(container, dialog);
  ((_window$Nostria$conta = (_window$Nostria = window.Nostria) === null || _window$Nostria === void 0 ? void 0 : _window$Nostria.container) !== null && _window$Nostria$conta !== void 0 ? _window$Nostria$conta : document.body).appendChild(dialog);
};
exports.dialog = dialog;
const nextpage = (params = {}) => {
  var _window$Nostria$conta2, _window$Nostria2;
  const _ref2 = params !== null && params !== void 0 ? params : {},
    header = _ref2.header,
    content = _ref2.content,
    validate = _ref2.validate,
    validateForm = _ref2.validateForm;
  const nextpage = document.createElement("n3-nextpage");
  const container = document.createElement("div");
  (0, _index.render)(content ? (0, _index.html)` ${content} ` : (0, _index.html)` <p>Êtes-vous certain de vouloir confirmer cette action ?</p> `, container);
  Object.assign(nextpage, {
    header,
    validate,
    validateForm,
    open: true,
    animateContainer: true
  });
  const events = ["close", "confirm", "reject"];
  events.map(x => {
    const event = `on${capitalizeFirstLetter(x)}`;
    !params[event] || nextpage.addEventListener(x, e => params[event](e.detail));
  });
  (0, _index.render)(container, nextpage);
  ((_window$Nostria$conta2 = (_window$Nostria2 = window.Nostria) === null || _window$Nostria2 === void 0 ? void 0 : _window$Nostria2.container) !== null && _window$Nostria$conta2 !== void 0 ? _window$Nostria$conta2 : document.body).appendChild(nextpage);
};
exports.nextpage = nextpage;
const filter = (that, {
  arrayKey,
  filterKey,
  key,
  value,
  filters
}) => {
  var _that$requestUpdate;
  if (value || (filters !== null && filters !== void 0 ? filters : []).some(({
    valueKey
  }) => that[valueKey])) {
    // prettier-ignore
    const l = val => {
      var _val$toLowerCase, _val$toLowerCase$norm;
      return val === null || val === void 0 ? void 0 : (_val$toLowerCase = val.toLowerCase()) === null || _val$toLowerCase === void 0 ? void 0 : (_val$toLowerCase$norm = _val$toLowerCase.normalize("NFD")) === null || _val$toLowerCase$norm === void 0 ? void 0 : _val$toLowerCase$norm.replace(/[\u0300-\u036f]/g, "");
    };
    const array = typeof arrayKey === "string" ? that[arrayKey] : arrayKey;
    const strInc = (item, key, val) => {
      var _l2;
      if (key.includes(";")) return key.split(";").some(k => {
        var _l;
        return (_l = l(item[k])) === null || _l === void 0 ? void 0 : _l.includes(l(val));
      });else return (_l2 = l(item[key])) === null || _l2 === void 0 ? void 0 : _l2.includes(l(val));
    };
    if (value) {
      var _array$filter;
      value = value.split(" ");
      that[filterKey] = (_array$filter = array.filter(item => value.every(val => strInc(item, key, val)))) !== null && _array$filter !== void 0 ? _array$filter : [];
    } else that[filterKey] = array;
    if (filters && filters.length > 0) {
      that[filterKey] = that[filterKey].filter(item => filters.every(({
        valueKey: v,
        key
      }) => {
        var _that$v$split;
        return !that[v] || ((_that$v$split = that[v].split(" ")) !== null && _that$v$split !== void 0 ? _that$v$split : []).every(val => strInc(item, key, val));
      }));
    }
  } else that[filterKey] = null;
  (_that$requestUpdate = that.requestUpdate) === null || _that$requestUpdate === void 0 ? void 0 : _that$requestUpdate.call(that);
};
exports.filter = filter;
const virtualizer = ({
  items = [],
  renderItem,
  scrollTarget = null,
  length = 50,
  noStrict = false
}) => {
  return (0, _index.html)`
    <style>
      ${noStrict ? (0, _index.css)`
            #n3_virtualizer {
              contain: initial !important;
            }
          ` : ""}
    </style>
    ${(items === null || items === void 0 ? void 0 : items.length) > length ? (0, _index.html)`
          <style>
            lit-virtualizer {
              width: 100%;
              height: 100%;
            }
          </style>
          <lit-virtualizer
            id="n3_virtualizer"
            .items="${items}"
            .renderItem="${(...args) => renderItem(...args)}"
            .scrollTarget="${scrollTarget !== null && scrollTarget !== void 0 ? scrollTarget : null}"
          >
          </lit-virtualizer>
        ` : (0, _index.html)`
          <div id="n3_virtualizer" ?no-contain="${!!scrollTarget}" .scrollTarget="${scrollTarget}">
            <style>
              #n3_virtualizer {
                display: block;
                position: relative;
                contain: strict;
                overflow: auto;
                width: 100%;
                height: 100%;
              }
              #n3_virtualizer[no-contain] {
                contain: initial;
              }
            </style>
            ${items === null || items === void 0 ? void 0 : items.map((...args) => renderItem(...args))}
          </div>
        `}
  `;
};
exports.virtualizer = virtualizer;
const virtualizerScrollTo = async (that, index = 0, element) => {
  var _ref3, _that$shadowRoot, _virtu$tagName;
  await that.updateComplete;
  const virtu = (_ref3 = element !== null && element !== void 0 ? element : that === null || that === void 0 ? void 0 : that.querySelector("#n3_virtualizer")) !== null && _ref3 !== void 0 ? _ref3 : (_that$shadowRoot = that.shadowRoot) === null || _that$shadowRoot === void 0 ? void 0 : _that$shadowRoot.querySelector("#n3_virtualizer");
  if (!virtu) return;
  if (((_virtu$tagName = virtu.tagName) === null || _virtu$tagName === void 0 ? void 0 : _virtu$tagName.toLowerCase()) === "lit-virtualizer") {
    virtu.scrollToIndex(index, "center");
  } else {
    var _filter;
    const element = (_filter = [...virtu.childNodes].filter(el => el instanceof HTMLElement)) === null || _filter === void 0 ? void 0 : _filter[index + 1];
    const target = virtu.scrollTarget;
    setTimeout(() => {
      let top = null;
      if (element) {
        top = element.offsetTop - (target !== null && target !== void 0 ? target : virtu).offsetHeight / 2 + element.offsetHeight / 2;
      } else top = 0;
      (target !== null && target !== void 0 ? target : virtu).scrollTop = top;
    });
  }
};
exports.virtualizerScrollTo = virtualizerScrollTo;
const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
exports.capitalizeFirstLetter = capitalizeFirstLetter;
const getClosest = (element, tag) => {
  var _element$getRootNode, _ref4, _element$closest, _host$tagName;
  const host = element === null || element === void 0 ? void 0 : (_element$getRootNode = element.getRootNode()) === null || _element$getRootNode === void 0 ? void 0 : _element$getRootNode.host;
  if (!host) return null;
  return (_ref4 = (_element$closest = element === null || element === void 0 ? void 0 : element.closest(tag)) !== null && _element$closest !== void 0 ? _element$closest : host === null || host === void 0 ? void 0 : host.closest(tag)) !== null && _ref4 !== void 0 ? _ref4 : (host === null || host === void 0 ? void 0 : (_host$tagName = host.tagName) === null || _host$tagName === void 0 ? void 0 : _host$tagName.toLowerCase()) === tag ? host : getClosest(host, tag);
};
exports.getClosest = getClosest;
const waitFor = (func, timeout = 5000) => {
  return new Promise(resolve => {
    let waitForTimeout, toBeTrueTimeout;
    const clearTimeouts = () => {
      window.clearTimeout(toBeTrueTimeout);
      window.clearTimeout(waitForTimeout);
    };
    const toBeTrue = () => {
      if (!!func()) {
        resolve(func());
        clearTimeouts();
      } else toBeTrueTimeout = setTimeout(() => toBeTrue());
    };
    waitForTimeout = setTimeout(() => {
      resolve(func());
      clearTimeouts();
    }, timeout);
    toBeTrue();
  });
};
exports.waitFor = waitFor;
const chunk = (arr, size) => {
  let array = [];
  for (var i = 0; i < arr.length; i += size) array = [...array, arr.slice(i, i + size)];
  return array;
};
exports.chunk = chunk;
const touch = (element, func = () => {}) => {
  let _touches = [];
  element.addEventListener("touchstart", e => {
    [...e.touches].map(({
      identifier,
      pageX,
      pageY
    }) => {
      if (_touches.every(({
        identifier: id
      }) => id !== identifier)) _touches.push({
        identifier,
        startX: pageX,
        startY: pageY
      });
    });
    func({
      type: "start",
      touches: [..._touches],
      event: e
    });
  });
  element.addEventListener("touchmove", e => {
    let moves = [];
    [...e.touches].map(({
      identifier,
      pageX,
      pageY
    }) => {
      const touch = _touches.find(({
        identifier: id
      }) => identifier == id);
      if (touch) moves.push({
        identifier,
        x: pageX - touch.startX,
        y: pageY - touch.startY
      });
    });
    func({
      type: "move",
      moves,
      event: e
    });
  });
  element.addEventListener("touchend", e => {
    _touches.map(({
      identifier
    }, i) => {
      if ([...e.touches].every(t => t.identifier !== identifier)) {
        _touches.splice(i, 1);
        i--;
      }
    });
    func({
      type: "end",
      event: e
    });
  });
};
exports.touch = touch;
const rem2px = rem => rem.trim().replace("rem", "") * parseFloat(getComputedStyle(document.documentElement).fontSize);
exports.rem2px = rem2px;
const getCssProp = (prop, element = document.body) => getComputedStyle(element).getPropertyValue(prop);
exports.getCssProp = getCssProp;
const entriesReduce = (object, key = "k", value = "v") => {
  return Object.entries(object).reduce((arr, [k, v]) => arr = [...arr, {
    [key]: k,
    [value]: v
  }], []);
};
exports.entriesReduce = entriesReduce;