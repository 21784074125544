"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.writtenNumber = exports.default = void 0;
const i18n = {
  fr: {
    useLongScale: false,
    baseSeparator: "-",
    unitSeparator: "",
    base: {
      "0": "zéro",
      "1": "un",
      "2": "deux",
      "3": "trois",
      "4": "quatre",
      "5": "cinq",
      "6": "six",
      "7": "sept",
      "8": "huit",
      "9": "neuf",
      "10": "dix",
      "11": "onze",
      "12": "douze",
      "13": "treize",
      "14": "quatorze",
      "15": "quinze",
      "16": "seize",
      "17": "dix-sept",
      "18": "dix-huit",
      "19": "dix-neuf",
      "20": "vingt",
      "30": "trente",
      "40": "quarante",
      "50": "cinquante",
      "60": "soixante",
      "70": "soixante-dix",
      "80": "quatre-vingt",
      "90": "quatre-vingt-dix"
    },
    units: [{
      singular: "cent",
      plural: "cents",
      avoidInNumberPlural: true,
      avoidPrefixException: [1]
    }, {
      singular: "mille",
      avoidPrefixException: [1]
    }, {
      singular: "million",
      plural: "millions"
    }, {
      singular: "milliard",
      plural: "milliards"
    }, {
      singular: "billion",
      plural: "billions"
    }, {
      singular: "billiard",
      plural: "billiards"
    }, {
      singular: "trillion",
      plural: "trillions"
    }, {
      singular: "trilliard",
      plural: "trilliards"
    }, {
      singular: "quadrillion",
      plural: "quadrillions"
    }, {
      singular: "quadrilliard",
      plural: "quadrilliards"
    }, {
      singular: "quintillion",
      plural: "quintillions"
    }, {
      singular: "quintilliard",
      plural: "quintilliards"
    }, {
      singular: "sextillion",
      plural: "sextillions"
    }, {
      singular: "sextilliard",
      plural: "sextilliards"
    }, {
      singular: "septillion",
      plural: "septillions"
    }, {
      singular: "septilliard",
      plural: "septilliards"
    }, {
      singular: "octillion",
      plural: "octillions"
    }],
    unitExceptions: {
      "21": "vingt et un",
      "31": "trente et un",
      "41": "quarante et un",
      "51": "cinquante et un",
      "61": "soixante et un",
      "71": "soixante et onze",
      "72": "soixante-douze",
      "73": "soixante-treize",
      "74": "soixante-quatorze",
      "75": "soixante-quinze",
      "76": "soixante-seize",
      "77": "soixante-dix-sept",
      "78": "soixante-dix-huit",
      "79": "soixante-dix-neuf",
      "80": "quatre-vingts",
      "91": "quatre-vingt-onze",
      "92": "quatre-vingt-douze",
      "93": "quatre-vingt-treize",
      "94": "quatre-vingt-quatorze",
      "95": "quatre-vingt-quinze",
      "96": "quatre-vingt-seize",
      "97": "quatre-vingt-dix-sept",
      "98": "quatre-vingt-dix-huit",
      "99": "quatre-vingt-dix-neuf"
    }
  }
};
let shortScale = [100];
for (let i = 1; i <= 16; i++) {
  shortScale.push(Math.pow(10, i * 3));
}
let longScale = [100, 1000];
for (let i = 1; i <= 15; i++) {
  longScale.push(Math.pow(10, i * 6));
}
const defaults = (target, defs) => {
  if (target == null) target = {};
  var ret = {};
  var keys = Object.keys(defs);
  for (var i = 0, len = keys.length; i < len; i++) {
    var key = keys[i];
    ret[key] = target[key] || defs[key];
  }
  return ret;
};
const writtenNumber = (n, options) => {
  options = options || {};
  options = defaults(options, {
    noAnd: true,
    alternativeBase: null,
    lang: "fr"
  });
  if (n < 0) {
    return "";
  }
  n = Math.round(+n);
  let language = typeof options.lang === "string" ? i18n[options.lang] : options.lang;
  if (!language) {
    if (languages.indexOf(writtenNumber.defaults.lang) < 0) {
      writtenNumber.defaults.lang = "fr";
    }
    language = i18n[writtenNumber.defaults.lang];
  }
  let scale = language.useLongScale ? longScale : shortScale;
  let units = language.units;
  let unit;
  if (!(units instanceof Array)) {
    let rawUnits = units;
    units = [];
    scale = Object.keys(rawUnits);
    for (let i in scale) {
      units.push(rawUnits[scale[i]]);
      scale[i] = Math.pow(10, parseInt(scale[i]));
    }
  }
  let baseCardinals = language.base;
  let alternativeBaseCardinals = options.alternativeBase ? language.alternativeBase[options.alternativeBase] : {};
  if (language.unitExceptions[n]) return language.unitExceptions[n];
  if (alternativeBaseCardinals[n]) return alternativeBaseCardinals[n];
  if (baseCardinals[n]) return baseCardinals[n];
  if (n < 100) return handleSmallerThan100(n, language, unit, baseCardinals, alternativeBaseCardinals, options);
  let m = n % 100;
  let ret = [];
  if (m) {
    if (options.noAnd && !(language.andException && language.andException[10])) ret.push(writtenNumber(m, options));else ret.push(language.unitSeparator + writtenNumber(m, options));
  }
  let firstSignificant;
  for (let i = 0, len = units.length; i < len; i++) {
    let r = Math.floor(n / scale[i]);
    let divideBy;
    if (i === len - 1) divideBy = 1000000;else divideBy = scale[i + 1] / scale[i];
    r %= divideBy;
    unit = units[i];
    if (!r) continue;
    firstSignificant = scale[i];
    if (unit.useBaseInstead) {
      let shouldUseBaseException = unit.useBaseException.indexOf(r) > -1 && (unit.useBaseExceptionWhenNoTrailingNumbers ? i === 0 && ret.length : true);
      if (!shouldUseBaseException) ret.push(alternativeBaseCardinals[r * scale[i]] || baseCardinals[r * scale[i]]);else ret.push(r > 1 && unit.plural ? unit.plural : unit.singular);
      continue;
    }
    let str;
    if (typeof unit === "string") {
      str = unit;
    } else if (r === 1 || unit.useSingularEnding && r % 10 === 1 && (!unit.avoidEndingRules || unit.avoidEndingRules.indexOf(r) < 0)) {
      str = unit.singular;
    } else if (unit.few && (r > 1 && r < 5 || unit.useFewEnding && r % 10 > 1 && r % 10 < 5 && (!unit.avoidEndingRules || unit.avoidEndingRules.indexOf(r) < 0))) {
      str = unit.few;
    } else {
      str = unit.plural && (!unit.avoidInNumberPlural || !m) ? unit.plural : unit.singular;
      str = r === 2 && unit.dual ? unit.dual : str;
      str = r > 10 && unit.restrictedPlural ? unit.singular : str;
    }
    if (unit.avoidPrefixException && unit.avoidPrefixException.indexOf(r) > -1) {
      ret.push(str);
      continue;
    }
    let exception = language.unitExceptions[r];
    let number = exception || writtenNumber(r, defaults({
      noAnd: !(language.andException && language.andException[r] || unit.andException) && true,
      alternativeBase: unit.useAlternativeBase
    }, options));
    n -= r * scale[i];
    ret.push(number + " " + str);
  }
  let firstSignificantN = firstSignificant * Math.floor(n / firstSignificant);
  let rest = n - firstSignificantN;
  if (language.andWhenTrailing && firstSignificant && 0 < rest && ret[0].indexOf(language.unitSeparator) !== 0) {
    ret = [ret[0], language.unitSeparator.replace(/\s+$/, "")].concat(ret.slice(1));
  }
  if (language.allSeparator) {
    for (let j = 0; j < ret.length - 1; j++) {
      ret[j] = language.allSeparator + ret[j];
    }
  }
  let result = ret.reverse().join(" ");
  return result;
};
exports.writtenNumber = writtenNumber;
const handleSmallerThan100 = (n, language, unit, baseCardinals, alternativeBaseCardinals, options) => {
  let dec = Math.floor(n / 10) * 10;
  unit = n - dec;
  if (unit) {
    return alternativeBaseCardinals[dec] || baseCardinals[dec] + language.baseSeparator + writtenNumber(unit, options);
  }
  return alternativeBaseCardinals[dec] || baseCardinals[dec];
};
var _default = writtenNumber;
exports.default = _default;