"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.generateColors = exports.default = void 0;
var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));
const lighter = {
  50: 0.92,
  100: 0.87,
  200: 0.76,
  300: 0.55,
  400: 0.35,
  500: 0.15
};
const darker = {
  700: 0.8,
  800: 0.7,
  900: 0.6
};
const darkMode = {
  50: [5, 7],
  100: [4, 6],
  200: [3.5, 5],
  300: [2, 3.5],
  400: [1.5, 2]
};
const percent = "5%";
const generateColors = color => {
  if (!color) return;
  let colors = [];
  for (const key in lighter) {
    const tint = lighter[key];
    const dark = darkMode[key];
    const _tintColor = tintColor(color, tint, dark),
      _tintColor2 = (0, _slicedToArray2.default)(_tintColor, 2),
      tinted = _tintColor2[0],
      rgb = _tintColor2[1];
    colors.push({
      key,
      rgb,
      background: tinted,
      text: getTextColor(tinted)
    });
  }
  // Base
  colors.push({
    key: "600",
    background: `#${color}`,
    text: getTextColor(color)
  });
  // Shades
  for (const key in darker) {
    const shade = darker[key];
    const _shadeColor = shadeColor(color, shade),
      _shadeColor2 = (0, _slicedToArray2.default)(_shadeColor, 2),
      shaded = _shadeColor2[0],
      rgb = _shadeColor2[1];
    colors.push({
      key,
      rgb,
      background: shaded,
      text: getTextColor(shaded)
    });
  }
  const _colors$7$rgb$split = colors[7].rgb.split(", "),
    _colors$7$rgb$split2 = (0, _slicedToArray2.default)(_colors$7$rgb$split, 3),
    r = _colors$7$rgb$split2[0],
    g = _colors$7$rgb$split2[1],
    b = _colors$7$rgb$split2[2];
  const H = Math.round(rgbToHsl(r, g, b)[0] * 360);
  const hsGrays = {
    50: `${H || 0}, ${!isNaN(H) ? percent : "0%"}`,
    100: `${H || 0}, ${!isNaN(H) ? percent : "0%"}`,
    200: `${H || 0}, ${!isNaN(H) ? percent : "0%"}`,
    300: `${H || 0}, ${!isNaN(H) ? percent : "0%"}`,
    400: `${H || 0}, ${!isNaN(H) ? percent : "0%"}`,
    500: `${H || 0}, ${!isNaN(H) ? percent : "0%"}`,
    600: `${H || 0}, ${!isNaN(H) ? percent : "0%"}`,
    700: `${H || 0}, ${!isNaN(H) ? percent : "0%"}`,
    800: `${H || 0}, ${!isNaN(H) ? percent : "0%"}`,
    900: `${H || 0}, ${!isNaN(H) ? percent : "0%"}`
  };
  Object.entries(hsGrays).forEach(([key, val]) => document.documentElement.style.setProperty(`--hs-gray-${key}`, val));
  colors.forEach(color => {
    document.documentElement.style.setProperty(`--color-primary-${color.key}-hsl`, hexToHsl(color.background));
    document.documentElement.style.setProperty(`--text-color-primary-${color.key}`, color.text);
    if (color.key == 200) document.documentElement.style.setProperty(`--color-primary-rgb-200`, color.rgb);
  });
};
exports.generateColors = generateColors;
const tintColor = (hex, intensity, [S, L] = []) => {
  let color = hexToRgb(hex),
    r = Math.round(color.r + (255 - color.r) * intensity),
    g = Math.round(color.g + (255 - color.g) * intensity),
    b = Math.round(color.b + (255 - color.b) * intensity);
  if (document.body.hasAttribute("dark") && S && L) {
    let _rgbToHsl = rgbToHsl(r, g, b),
      _rgbToHsl2 = (0, _slicedToArray2.default)(_rgbToHsl, 3),
      h = _rgbToHsl2[0],
      s = _rgbToHsl2[1],
      l = _rgbToHsl2[2];
    s /= S, l /= L;
    var _hslToRgb = hslToRgb(h, s, l);
    var _hslToRgb2 = (0, _slicedToArray2.default)(_hslToRgb, 3);
    r = _hslToRgb2[0];
    g = _hslToRgb2[1];
    b = _hslToRgb2[2];
  }
  return [rgbToHex(r, g, b), `${r}, ${g}, ${b}`];
};
const shadeColor = (hex, intensity) => {
  const color = hexToRgb(hex),
    r = Math.round(color.r * intensity),
    g = Math.round(color.g * intensity),
    b = Math.round(color.b * intensity);
  return [rgbToHex(r, g, b), `${r}, ${g}, ${b}`];
};
const hexPart = c => `0${c.toString(16)}`.slice(-2);
const rgbToHex = (r, g, b) => {
  return `#${hexPart(r)}${hexPart(g)}${hexPart(b)}`;
};
const hexToRgb = hex => {
  const color = `#${hex}`,
    components = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
  if (!components) {
    return null;
  }
  return {
    r: parseInt(components[1], 16),
    g: parseInt(components[2], 16),
    b: parseInt(components[3], 16)
  };
};
const hexToHsl = hex => {
  const _hexToRgb = hexToRgb(hex.replace("#", "")),
    r = _hexToRgb.r,
    g = _hexToRgb.g,
    b = _hexToRgb.b;
  const _rgbToHsl3 = rgbToHsl(r, g, b),
    _rgbToHsl4 = (0, _slicedToArray2.default)(_rgbToHsl3, 3),
    h = _rgbToHsl4[0],
    s = _rgbToHsl4[1],
    l = _rgbToHsl4[2];
  return `${Math.round(h * 360)}, ${Math.round(s * 100)}%, ${Math.round(l * 100)}%`;
};
const getTextColor = color => {
  const _hexToRgb2 = hexToRgb(color.replace(/#/gi, "")),
    r = _hexToRgb2.r,
    g = _hexToRgb2.g,
    b = _hexToRgb2.b,
    luma = 0.2126 * r + 0.7152 * g + 0.0722 * b;
  return luma < 150 ? "#FFFFFF" : "#333333";
};
const rgbToHsl = (r, g, b) => {
  r /= 255, g /= 255, b /= 255;
  let max = Math.max(r, g, b),
    min = Math.min(r, g, b);
  let h,
    s,
    l = (max + min) / 2;
  if (max == min) {
    h = s = 0; // achromatic
  } else {
    let d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h /= 6;
  }
  return [h, s, l];
};
const hslToRgb = (h, s, l) => {
  let r, g, b;
  if (s == 0) {
    r = g = b = l; // achromatic
  } else {
    let hue2rgb = function hue2rgb(p, q, t) {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    };
    let q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    let p = 2 * l - q;
    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }
  return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
};
var _default = generateColors;
exports.default = _default;