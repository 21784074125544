"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  /** PRIMARY **/
  primary: "bg-primary-600 hover:bg-primary-500 border-transparent focus:border-primary-700 active:bg-primary-700 focus:ring ring-primary-500 ring-opacity-40 text-primary-text-600",
  secondary: "bg-primary-500 bg-opacity-10 hover:bg-opacity-20 active:bg-opacity-25 focus:border-primary-300 text-primary-700 focus:ring-2 ring-primary-500 ring-opacity-20",
  /** RED **/
  "red-primary": "bg-red-600 hover:bg-red-500 border-transparent focus:border-red-700 active:bg-red-700 focus:ring ring-red-500 ring-opacity-20 text-white",
  "red-secondary": "bg-red-600 border-transparent bg-opacity-10 hover:bg-opacity-20 active:bg-opacity-25 focus:border-red-300 text-red-600 focus:ring-2 ring-red-500 ring-opacity-40",
  /** ORANGE **/
  "orange-primary": "bg-orange-600 hover:bg-orange-500 border-transparent focus:border-orange-700 active:bg-orange-700 focus:ring-2 ring-orange-500 ring-opacity-20 text-white",
  "orange-secondary": "bg-orange-500 border-transparent bg-opacity-10 hover:bg-opacity-20 active:bg-opacity-25 focus:border-orange-300 text-orange-500 focus:ring-2 ring-orange-500 ring-opacity-20",
  /** GREEN **/
  "green-primary": "bg-green-600 hover:bg-green-500 border-transparent focus:border-green-600 active:bg-green-600 focus:ring ring-green-500 ring-opacity-40 text-white",
  "green-secondary": "bg-green-600 border-transparent bg-opacity-10 hover:bg-opacity-20 active:bg-opacity-25 focus:border-green-300 text-green-600 focus:ring-2 ring-green-500 ring-opacity-20",
  /** GRAY **/
  "gray-primary": "bg-gray-800 hover:bg-gray-700 border-transparent focus:border-primary-900 active:bg-gray-900 focus:ring-2 ring-gray-500 ring-opacity-20 text-gray-50",
  "gray-secondary": "bg-gray-500 border-transparent bg-opacity-10 hover:bg-opacity-20 active:bg-opacity-25 focus:border-primary-300 text-gray-700 focus:ring-2 ring-primary-500 ring-opacity-20",
  "gray-tertiary": "bg-gray-900 border-transparent bg-opacity-0 hover:bg-opacity-10 active:bg-opacity-10 focus:border-primary-300 text-gray-700 focus:ring-2 ring-primary-500 ring-opacity-20 shadow-none",
  /** WHITE **/
  white: "bg-white text-[#111] hover:text-gray-600 border-gray-200 focus:border-primary-300 active:bg-gray-50 active:text-gray-800 focus:ring-2 ring-primary-500 ring-opacity-20",
  /** NONE **/
  none: "text-current focus:ring-2 ring-opacity-20 shadow-none",
  // "text-current focus:border-primary-300 focus:ring-2 ring-primary-500 ring-opacity-20 shadow-none",

  /** DP-0 **/
  "dp-0": "bg-dp-0 text-gray-900 hover:text-gray-600 border-gray-200 hover:border-gray-300 focus:border-primary-300 active:bg-dp-2 active:text-gray-800 focus:ring-2 ring-primary-500 ring-opacity-20",
  /** DP-1 **/
  "dp-1": "bg-dp-1 text-gray-900 hover:text-gray-600 border-gray-200 hover:border-gray-300 focus:border-primary-300 active:bg-dp-2 active:text-gray-800 focus:ring-2 ring-primary-500 ring-opacity-20",
  /** DP-2 **/
  "dp-2": "bg-dp-2 text-gray-900 hover:text-gray-600 border-gray-200 hover:border-gray-300 focus:border-primary-300 active:bg-dp-1 active:text-gray-800 focus:ring-2 ring-primary-500 ring-opacity-20",
  /** DP-3 **/
  "dp-3": "bg-dp-3 text-gray-900 hover:text-gray-600 border-gray-200 hover:border-gray-300 focus:border-primary-300 active:bg-dp-1 active:text-gray-800 focus:ring-2 ring-primary-500 ring-opacity-20"
};
exports.default = _default;