"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _index = require("lit/index.js");
var _default = (0, _index.css)`input, textarea {
  box-sizing: border-box;
  font-family: inherit;
}

textarea {
  min-height: var(--textarea-min-height, 8rem);
}

input[type="time"]::-webkit-input-placeholder{ 
  visibility: hidden !important;
}

:host {
  /* --input-border-radius: 0.375rem; */
  --input-default-border-radius: 0.375rem;
  /* --input-border-color: hsla(var(--gray-900-hsl), 0.10); */
  /* --input-border-color: var(--dp-2); */
}
/* :host(:not([dark-mode])) {
  --input-border-color: var(--gray-300); 
} */

:host(:not([no-part])) [part="input"] input,
:host(:not([no-part])) [part="input"] textarea,
:host(:not([no-part])) [part="input"] ::slotted(input),
:host(:not([no-part])) [part="input"] ::slotted(textarea),
[input-part] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: var(--input-background-color, hsla(var(--gray-900-hsl), 0.06));
  border: 1px solid var(--input-border-color, transparent);
  /* border: 1px solid var(--input-border-color, hsla(var(--gray-900-hsl), 0.10)); */
  border-radius: var(--input-border-radius, var(--input-default-border-radius, 0.375));
  padding: var(--input-padding, 0.5rem 0.75rem);
  font-size: 1rem;
  line-height: 1.25;
  width: var(--input-min-width, 100%);
  transition: box-shadow 150ms ease-in-out, border-color 150ms ease-in-out;
  box-sizing: border-box;
  box-shadow: var(--input-shadow, 0 1px 2px 0 rgba(0,0,0,.05));
  font-family: var(--input-font-family, inherit);
  /* min-height: 2.375rem;
  height: 2.375rem; */
  color: var(--input-text-color, var(--gray-900));
  cursor: var(--input-cursor, text);
}

n3-input-container:hover [input-part],
:host(:not([no-part]):hover) [part="input"],
:host(:not([no-part]):hover) [part="input"] textarea,
:host(:not([no-part]):hover) [part="input"] ::slotted(input),
:host(:not([no-part]):hover) [part="input"] ::slotted(textarea),
[input-part]:hover {
  border-color: var(--input-hovered-border-color, hsla(var(--hs-gray-900), 13%, 0.12));
}
n3-input-container[no-filter] [input-part] {
  cursor: var(--input-cursor, pointer);
}

[input-part][small] {
  padding: var(--input-padding, 0.375rem 0.75rem);
}

:host(:not([no-part])) [part="input"] input,
:host(:not([no-part])) [part="input"] ::slotted(input),
[input-part] {
  text-overflow: ellipsis;
  overflow: hidden; 
  white-space: nowrap;
}

input::-webkit-input-placeholder {
  color: var(--input-text-color, var(--gray-900));
  opacity: 0.5;
}
input:-moz-placeholder {
  color: var(--input-text-color, var(--gray-900));
  opacity: 0.5;
}
input::-moz-placeholder {
  color: var(--input-text-color, var(--gray-900));
  opacity: 0.5;
}
input:-ms-input-placeholder {
  color: var(--input-text-color, var(--gray-900));
  opacity: 0.5;
}
input::-ms-input-placeholder {
  color: var(--input-text-color, var(--gray-900));
  opacity: 0.5;
}
input::placeholder {
  color: var(--input-text-color, var(--gray-900));
  opacity: 0.5;
}

:host(:not([no-part])) [part="input"] ::slotted(input:focus),
:host(:not([no-part])) [part="input"] ::slotted(textarea:focus),
:host(:not([no-part])) [part="input"] input:focus,
:host(:not([no-part])) [part="input"] textarea:focus,
n3-input-container[focused] [input-part] ,
n3-input-container[focused] > input,
n3-input-container[focused] > textarea {
  outline: none;

  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);

  --tw-ring-opacity: 0.25;
  --tw-ring-color: hsla(var(--color-primary-500-hsl),var(--tw-ring-opacity,0.3));
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width, 0px) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width, 0px)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 transparent);
  border-color: hsla(var(--color-primary-600-hsl), 0.6) !important;
}
@media (min-width:640px){
  :host(:not([no-part])) [part="input"] input,
  :host(:not([no-part])) [part="input"] textarea,
  :host(:not([no-part])) [part="input"] ::slotted(input),
  :host(:not([no-part])) [part="input"] ::slotted(textarea),
  [input-part] input,
  [input-part] textarea {
    font-size:.875rem !important;
    /* line-height: 1.25rem !important; */
  }
}


:host([not-valid]:not([focused]):not([first-validation]):not([no-part])) [part="input"] ::slotted(input),
:host([invalid]:not([no-part])) [part="input"] ::slotted(input),
n3-input-container[not-valid]:not([focused]):not([first-validation]) [input-part],
n3-input-container[invalid] [input-part],
:host([not-valid]:not([focused]):not([first-validation]):not([no-part])) [part="input"] ::slotted(textarea),
:host([invalid]:not([no-part])) [part="input"] ::slotted(textarea),
:host([not-valid]:not([focused]):not([first-validation]):not([no-part])) [part="input"] input,
:host([invalid]:not([no-part])) [part="input"] input,
:host([not-valid]:not([focused]):not([first-validation]):not([no-part])) [part="input"] textarea,
:host([invalid]:not([no-part])) [part="input"] textarea {
  color: var(--input-error-color, var(--red-900, #771d1d)) !important;
  border-color: var(--red-300, #ff335577) !important;
}

:host, n3-input-container {
  --input-error-label-color: var(--red-900);
  --input-error-color: var(--red-900);
}
:host([dark-mode]), n3-input-container[dark-mode] {
  --input-error-label-color: #faf6f6;
  --input-error-color: #fdeeee;
}

:host([not-valid]:not([focused]):not([first-validation]):not([no-part])) label,
:host([invalid]:not([no-part])) label,
n3-input-container[not-valid]:not([focused]):not([first-validation]) label,
n3-input-container[invalid] label {
  color: var(--input-error-label-color, var(--red-900, #9b1c1c)) !important;
}

 :host([input-as-container]) {
  --input-slotted-background-color: var(--darker-background-color);
  --input-slotted-hover-background-color: var(--darker-hover-background-color);
  --input-text-align: center;
  --input-flex: 0;
  --input-after-width: var(--input-min-width, 100%);
  --input-min-height: calc(34px + var(--space-s));
  --input-padding: 0;
  --input-margin: 0 var(--space-xs) 0 0;
  --input-border-radius: var(--border-radius);
  --input-container-background-color: transparent;
  --invalid-input-background-color: transparent;
  --invalid-input-hover-background-color: transparent;
  --input-hover-background-color: transparent;
  --invalid-slotted-input-background-color: var(--error-color-10pct);
  --invalid-slotted-input-hover-background-color: var(--error-color-20pct);
  --input-error-text-align: left;
} 

n3-input-container[disabled] input {
  cursor: not-allowed;
  box-shadow: none;
}

#input-part {
  margin-top: var(--input-margin-top, 0.125rem);
}`;
exports.default = _default;